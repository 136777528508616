import React from 'react'
import { css } from 'styled-components'

import { space, palette } from '../../../utils/tokens'

const FormTextArea = ({ onChange, value }) => {

  const handleChange = e => {
    onChange(e.target.value)
  }

  return (
    <div>
      <textarea
        value={value || ''}
        onChange={handleChange}
        rows={16}
        css={css({
          borderRadius: `${space[1]}px`,
          border: `1px solid ${palette.grey[50]}`,
          padding: `${space[2]}px`,
          width: `100%`,
          resize: `none`,
        })}
      />
    </div>
  )
}

export default FormTextArea