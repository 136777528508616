import React, { useContext } from 'react'
import { navigate } from 'gatsby'

import CustomQuoteLayout from '../../../views/custom-quote/components/CustomQuoteLayout'
import FormTextArea from '../../../views/custom-quote/components/FormTextArea'
import FormNavigation from '../../../views/custom-quote/components/FormNavigation'
import FormQuestion from '../../../views/custom-quote/components/FormQuestion'
import { CustomQuoteContext } from '../../../views/custom-quote/context/CustomQuoteManager'
import { CUSTOM_QUOTE_SEQUENCE_LINKS } from '../../../views/custom-quote/constants'

const ProjectInformation = () => {

  const { formData, updateFormData } = useContext(CustomQuoteContext)

  const handleTextChange = (value) => {
    updateFormData({
      ...formData,
      projectDescription: value,
    })
  }
  
  const handleSubmit = () => {
    navigate(CUSTOM_QUOTE_SEQUENCE_LINKS.projectInformation.next)
  }

  return (
    <CustomQuoteLayout>
      <FormQuestion
        title="Tell us more about your project."
        description="The more details that you can give us, the easier it will be for us to help you! If
        you have any special instructions or any requests, feel free to write it here as well."
      />
      <FormTextArea
        onChange={handleTextChange}
        value={formData && formData.projectDescription}
      />
      <FormNavigation
        onSubmit={handleSubmit}
        previousLink={CUSTOM_QUOTE_SEQUENCE_LINKS.projectInformation.previous}
      />
    </CustomQuoteLayout>
  )
}

export default ProjectInformation
